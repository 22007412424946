import React from 'react'

interface breachInfoProps {
    numberOfBreaches: number 
    email: string
}

const BreachInfo = (props:breachInfoProps) => {
        const openNewTab = () => {
        window.open('https://app.kybershield.com.au/auth/signup', '_top');
        };
  return (
    <div className='breach-count-container gap-20 '>
        <div className='p-32 breach-info-box'>
        <h3 className='text-72 text-red text-center margin-0'>
            {props?.numberOfBreaches}
        </h3>
        <p className='text-28 text-red margin-0'>
        BREACHES DETECTED!
        </p>
        <p className='margin-0'>
        A detailed breach report has been sent to your email <strong> {props?.email}</strong>
        </p>
        <p className='margin-0'>Remember to check your junk mail.</p>
        </div>

<div className='quick-action-container'>
        <div className='center'>
<button className='button' onClick={openNewTab}>
        Protect yourself with Kybershield
          </button>
        </div>
       
</div>
    </div>
  )
}

export default BreachInfo